import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthProvider } from "./contexts";
import { SignIn } from "./pages/SignIn";
import { Dashboard } from "./pages/Dashboard";
import { Patients } from "./pages/Patients";
import { Doctors } from "./pages/Doctors";
import { DoctorView } from "./pages/singleDoctor";
import { PatientView } from "./pages/patientDetails";
import { Appointments } from "./pages/appointments";
import { CovidTests } from "./pages/rt-pcr";
import { Tests } from "./pages/tests";
import { TestList } from "./pages/labs/testList";
import { Visits } from "./pages/visits";
import { LabInquiry } from "./pages/labs/labInquries";
import { ExcelToJson} from "./pages/ExcelToJson";
import { Transaction } from './pages/transaction'

function App() {
  return (
    <Router>
      <AuthProvider>
        <Switch>
          <div className="App">
            
            <Route path="/" exact component={Dashboard} />
            <Route path="/appointments" exact component={Appointments} />
            <Route path="/signin" exact component={SignIn} />
            <Route path="/patients" exact component={Patients} />
            <Route path="/doctors" exact component={Doctors} />
            <Route path="/rt-pcr" exact component={CovidTests} />
            <Route path="/tests" exact component={Tests} />
            <Route path="/data" exact component={TestList} />
            <Route path="/visits" exact component={Visits} />
            <Route path="/transactions" exact component={Transaction} />
            <Route path="/lab_inquries" exact component={LabInquiry} />
            <Route path="/doctorView/:docId" exact component={DoctorView} />
            <Route
              path="/patientView/:patientId"
              exact
              component={PatientView}
            />
            <Route path="/excel" exact component={ExcelToJson} />
          </div>
        </Switch>
      </AuthProvider>
    </Router>
  );
}

export default App;

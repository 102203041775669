import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import { LeftSideMenu } from "../components/leftSideMenu";
import { TopBar } from "../components/topBar";
import { useAuth } from "../contexts";

const PatientViewComp = props => {
    let [initialData, setInitialData] = useState();
    let [loggedIn, setLoggedIn] = useState(useAuth())

    let params = useParams()

  if (!loggedIn.currentUser) window.location.href='/signin'

    useEffect(() => {
        db.collection("patients")
          .doc(`${params.patientId}`)
          .onSnapshot((snap) => {
            setInitialData(snap.data());
          });
      }, []);

    if (initialData)
    return(
        <div className="px-10 pb-10">
        <div className="flex items-center mt-5 space-x-3">
          <Link
            onClick={(e) => {
                e.preventDefault();
                window.history.go(-1);
              }}
          >
            <img className="w-7 h-7" src="https://nuvocliniq-test.web.app/back.svg"></img>
          </Link>
          <div className=" text-base">Patient details</div>
        </div>
        <div className="w-full h-0.5 border-t-2 mt-3 border-blue-100"></div>
        <div className=" py-5 w-full sm:flex">
          <div className="sm:w-6/12 w-full">
            <div className="w-full mt-5 sm:mt-0">
              <div className="text-gray-500 text-sm">Patient's Name</div>
              <div
                className={`${
                  initialData.name
                    ? "bg-blue-100 text-base text-blue-600"
                    : "bg-red-100 text-red-600"
                } rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.name ? initialData.name : "Not available"}
              </div>
            </div>
            <div className="w-full mt-5 sm:mt-0">
              <div className="text-gray-500 text-sm">Patient's Number</div>
              <div className="rounded-md w-max text-base px-2 py-1 bg-blue-100 text-blue-600 mt-2 mb-5">
                {initialData.number ? initialData.number : "Not available"}
              </div>
            </div>

            <div className="w-full">
              <div className="text-gray-500 text-sm">Email Address</div>
              <div
                className={`${
                  initialData.email
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } rounded-md text-base px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.email ? initialData.email : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Address</div>
              <div className="pr-5">
                <div
                  className={`${
                    initialData.address
                      ? "bg-blue-100 text-blue-600"
                      : "bg-red-100 text-red-600"
                  } text-base max-w-full w-max  rounded-md px-2 py-1 mt-2 mb-5`}
                >
                  {initialData.address ? initialData.address : "Not available"}
                </div>
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Weight (in kilograms)</div>
              <div
                type="number"
                className={`${
                  initialData.weight
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.weight ? initialData.weight : "Not available"}
              </div>
            </div>
          </div>
          <div className="sm:w-6/12 w-full">
            <div className="w-full">
              <div className="text-gray-500 text-sm">City</div>
              <div
                className={`${
                  initialData.city
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.city ? initialData.city : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Gender</div>
              <div
                className={`${
                  initialData.gender
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.gender ? initialData.gender : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Age (in years)</div>
              <div
                type="number"
                className={`${
                  initialData.age
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.age ? initialData.age : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">
                Height (in centimeters)
              </div>
              <div
                className={`${
                  initialData.height
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.height ? initialData.height : "Not available"}
              </div>
            </div>
            <div className="w-full">
              <div className="text-gray-500 text-sm">Aadhar Number</div>
              <div
                className={`${
                  initialData.aadhar
                    ? "bg-blue-100 text-blue-600"
                    : "bg-red-100 text-red-600"
                } text-base rounded-md px-2 py-1 mt-2 mb-5 w-max`}
              >
                {initialData.aadhar ? initialData.aadhar : "Not available"}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-0.5 -mt-3 border-t-2 border-blue-100"></div>
      </div>
    )
    else if (!initialData)
    return(<div></div>)
}

export const PatientView = props => {
    return (
    <div className=" min-w-full min-h-screen flex overflow-hidden overscroll-none  bg-white">
      <LeftSideMenu active="patients" />
      <div name="otherThanLeftMenu" className="w-full h-full">
        <TopBar />
        <div className="text-black ml-5 flex justify-start">
          <PatientViewComp />
        </div>
      </div>
    </div>
    )
} 
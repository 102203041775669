import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { db } from "../firebase";

import { useAuth } from "../contexts";
import { LeftSideMenu } from "../components/leftSideMenu";
import { TopBar } from "../components/topBar";

export const Patients = (props) => {
  let [patientArray, setPatientArray] = useState();
  let [last, setLast] = useState();
  let [linkText, setLinkText] = useState("View More");
  let [loggedIn, setLoggedIn] = useState(useAuth());

  useEffect(() => {
    const initialCredentials = async () => {
      if (!patientArray) {
        const first = db.collection("patients").orderBy("name").limit(8);
        const docSnap = await first.onSnapshot((snapshot) => {
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          setPatientArray(snapshot.docs);
          setLast(lastItem);
        });
      }
    };
    return initialCredentials();
  }, []);

  const updateCredentials = async () => {
    if (patientArray) {
      const next = db
        .collection("patients")
        .orderBy("name")
        .startAfter(last.data().name)
        .limit(8);
      const snapshot = await next.get();
      if (snapshot.docs.length !== 0) {
        const lastItem = snapshot.docs[snapshot.docs.length - 1];
        setPatientArray([...patientArray, ...snapshot.docs]);
        setLast(lastItem);
        //console.log("next", doctorsArray, last.data());
      } else setLinkText("No More Patients");
    }
  };

  const PatientCard = (props) => {
    let item = props.item;
    return (
      <Link
        onClick={(e) => {
          e.preventDefault();
          window.location.href = `/patientView/${item.id}`;
        }}
        className="rounded-md border-2 border-gray-100 shadow-md m-1 sm:m-3 pb-2"
      >
        <div className="w-52 sm:w-72 p-3">
          <div className="sm:flex  w-max sm:mr-5 ml-20 sm:ml-0">
            <img
              className=" w-10 h-10 rounded-full mr-3"
              src={
                item.data().img
                  ? item.data().img
                  : "https://nuvocliniq-test.web.app/man.png"
              }
            ></img>

            <div className="flex flex-col justify-center w-60 ">
              <div className="font-semibold w-max max-w-full text-base">
                {item.data().name}
              </div>
              <div className="text-sm">{item.data().number}</div>
            </div>
          </div>
        </div>
        <div className="flex px-3 my-1">
          <div
            style={{ paddingBottom: "2px", paddingTop: "2px" }}
            className={` text-green-600 bg-green-100  w-max rounded  px-2 text-sm`}
          >
            {item.data().gender}
          </div>
        </div>
      </Link>
    );
  };
  const PatientList = (props) => {
    if (!patientArray) return <div></div>;
    else if (patientArray)
      return (
        <div className=" lg:flex flex-wrap justify-start overflow-hidden overscroll-none">
          {patientArray.map((item) => {
            return <PatientCard item={item} />;
          })}
        </div>
      );
  };

  return (
    <div className=" min-w-full min-h-screen flex overflow-hidden overscroll-none  bg-white">
      <LeftSideMenu active="patients" />
      <div name="otherThanLeftMenu" className="w-full h-full">
        <TopBar />
        <Link
          onClick={(e) => {
            e.preventDefault();
            window.history.go(-1);
          }}
          className="flex items-center text-gray-700 w-full justify-start sm:ml-3 px-2 py-5 sm:p-5"
        >
          <img
            className="w-7 h-7 outline-none"
            src="https://nuvocliniq-test.web.app/back.svg"
          ></img>
          <div className="mx-4">List of patients</div>
        </Link>
        <div name="PatientList" className="text-black ml-5 flex justify-start">
          <PatientList />
        </div>
        <div className="ml-8 mt-3 mb-5">
          <Link
            className={`${linkText==="View More"?'bg-green-600':'bg-red-600'}  rounded px-3 py-2 w-max text-xs  text-white`}
            onClick={updateCredentials}
          >
            {linkText}
          </Link>
        </div>
      </div>
    </div>
  );
};

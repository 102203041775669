import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { db } from "../../firebase";

import { useAuth } from "../../contexts";
import { LeftSideMenu } from "../../components/leftSideMenu";
import { TopBar } from "../../components/topBar";

export const TestList = (props) => {
  let [testArray, settestArray] = useState();
  let [last, setLast] = useState();
  let [linkText, setLinkText] = useState("View More");
  let [currentComp, setCurrentComp] = useState("testList");
  let [currentItem, setCurrentItem] = useState();
  let [loggedIn, setLoggedIn] = useState(useAuth());

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  useEffect(() => {
    const initialCredentials = async () => {
      if (!testArray) {
        const first = db
          .collection("test")
          .orderBy("createAt", "desc")
          .limit(10);
        const docSnap = await first.onSnapshot((snapshot) => {
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          settestArray(snapshot.docs);
          setLast(lastItem);
        });
      }
    };
    //return initialCredentials();
  }, []);

  const updateCredentials = async () => {
    if (testArray) {
      const next = db
        .collection("test")
        .orderBy("createAt", "desc")
        .startAfter(last.data().createAt)
        .limit(10);
      const snapshot = await next.get();
      if (snapshot.docs.length !== 0) {
        const lastItem = snapshot.docs[snapshot.docs.length - 1];
        settestArray([...testArray, ...snapshot.docs]);
        setLast(lastItem);
        //console.log("next", doctorsArray, last.data());
      } else setLinkText("No More Tests");
    }
  };

  const Addtest = props => {
    let [newTest, setnewTest] = useState(false)

    let nameRef = useRef()
    let priceRef = useRef()
    let sampleRef = useRef()
    let priorityRef = useRef()
    let providerNameRef = useRef()
    let testCodeRef = useRef()
    let providerEmailRef = useRef()

    const handleNewTest = async (e) => {
        e.preventDefault()
        let name = nameRef.current.value
        let price = priceRef.current.value
        //let sample = sampleRef.current.value
        let priority = priorityRef.current.value
        //let providerName = providerNameRef.current.value
        let testCode = testCodeRef.current.value
        //let providerEmail = providerEmailRef.current.value

        if (!name || !price || !priority  || !testCode ) {
          sendToast("All fields are important.", "error")
         }
        else if (parseInt(priority)<0) sendToast('Enter valid priority.', "error")
        else if (parseInt(price)<0) sendToast('Enter valid price.', "error")
        else if (name.trim().length===0) sendToast('Enter valid name.', 'error')
       // else if (sample.trim().length===0) sendToast('Enter valid sample.', 'error')
        //else if (providerName.trim().length===0) sendToast('Enter valid provider name.', 'error')
        else {
            let testRef = await db.collection('labs').doc('atxvLYeDlQEMi4w72GBH').collection('testList').doc()
            let toAddTest = {
                id: testRef.id,
                priority: parseInt(priority),
                price: parseFloat(price),
                //providerName, testCode, providerEmail,
                name,  
                sample:'None',
                states:true,
                testCode: testCode
           }
           //console.log(toAddTest)
           testRef.set(toAddTest).then(res => {
               sendToast("Successfully added new test.", "success")
               setnewTest(false)
           })
           .catch(err => sendToast("Something went wrong.", "error"))
        }
    }
    
    return (
        <div className="flex justify-end">
            <div className=" ">
              <Link
                onClick={(e)=> {
                    e.preventDefault()
                    setnewTest(true)
                }}
                className={` rounded-md space-x-2 text-sm bg-blue-600 hover:bg-blue-700 text-white flex justify-center items-center px-3 mr-5 lg:mx-10 py-2 w-max`}
                >
                <i class="far fa-calendar-plus"></i>
                <div>New test</div>
              </Link>
              <div
                className={`${
                  newTest ? "visible" : "hidden"
                } fixed top-32 sm:right-12 right-3 z-10 px-4 bg-white py-3 border-2 border-gray-100 rounded-md shadow-md`}
                >
                <div>
                    <div className='my-2'>Test name</div>
                    <input ref={nameRef} placeholder='Enter test name'
                     className='border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md'></input>
                </div>
                <div>
                    <div className='my-2'>Price</div>
                    <input type="number" ref={priceRef} placeholder='Enter test price'
                     className='border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md'></input>
                </div>
                <div>
                    <div className='my-2'>Priority</div>
                    <input type="number" min="0" step="1" ref={priorityRef} placeholder='Enter test price'
                     className='border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md'></input>
                </div>
                <div>
                    <div className='my-2'>Sample</div>
                    <input ref={sampleRef} placeholder='Enter sample type'
                     className='border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md'></input>
                </div>
                {/* <div>
                    <div className='my-2'>Service provider name</div>
                    <input ref={providerNameRef} placeholder='Enter service provider name'
                     className='border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md'></input>
                </div> */}
                <div>
                    <div className='my-2'>Test code</div>
                    <input ref={testCodeRef} placeholder='Enter test code'
                     className='border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md'></input>
                </div>
                {/* <div>
                    <div className='my-2'>Service provider email</div>
                    <input ref={providerEmailRef} placeholder='Enter service provider email'
                     className='border-2 border-gray-100 outline-none w-full py-1 px-2 rounded-md'></input>
                </div> */}
                
                <div className="flex justify-end space-x-3 mt-3 text-sm">
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      setnewTest(false)
                    }}
                    className="bg-gray-200 px-2 py-1 text-black rounded"
                  >
                    Cancel
                  </Link>
                  <Link
                    onClick={handleNewTest}
                    className="bg-blue-600 px-2 py-1 text-white rounded"
                  >
                    Add
                  </Link>
                </div>
              </div>
            </div>
          </div>
    )
}


  const TestCard = (props) => {
    let item = props.item.data();
    return (
      <Link
        onClick={(e) => {
          e.preventDefault();
          setCurrentItem(item);
          setCurrentComp("testDetails");
        }}
        className="rounded-md border-2 border-gray-100 shadow-md m-2 sm:m-3 pb-2"
      >
        <div className="w-52 sm:w-72 my-1">
          <div
            style={{ paddingBottom: "2px", paddingTop: "2px" }}
            className={`text-gray-500 mt-3 mb-2 pr-3  w-full flex justify-end rounded-md  px-2 text-sm`}
          >
            {`${item.createAt.toDate()}`.slice(0, 25)}
          </div>
          <div className="flex justify-between items-center">
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className={` w-max rounded pt-2 mb-3 px-4 text-base font-bold`}
            >
              {item.name}
            </div>
            <div className="mr-3 bg-green-100 text-green-600 px-2 py-1 rounded">
              ₹{item.amount}
            </div>
          </div>
          <div className="space-y-1 px-3 w-full">
            {item.tests.map((testItem, index) => {
              return (
                <div className="flex">
                  <div>{index + 1}.</div>
                  <div className="w-full ml-2">{testItem.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </Link>
    );
  };
  const TestList = (props) => {
    if (!testArray) return <div></div>;
    else if (testArray)
      return (
        <div className=" lg:flex flex-wrap justify-start overflow-hidden overscroll-none">
          {testArray.map((item) => {
            return <TestCard item={item} />;
          })}
        </div>
      );
  };
  if (currentComp === "testList")
    return (
    <>
    <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <div className=" min-w-full min-h-screen flex overflow-hidden overscroll-none  bg-white">
        <LeftSideMenu active="data" />
        <div name="otherThanLeftMenu" className="w-full h-full">
          <TopBar />
          <div className="sm:flex justify-start items-center w-full pr-5">
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.history.go(-1);
              }}
              className="flex items-center text-gray-700  justify-start sm:ml-3 px-2 py-5 sm:p-5"
            >
              <img
                className="w-7 h-7 outline-none"
                src="https://nuvocliniq-test.web.app/back.svg"
              ></img>
              <div className="mx-4">List of tests</div>
            </Link>
          </div>
          <Addtest/>
          {/* <div name="TestList" className="text-black ml-5 flex justify-start">
            <TestList />
          </div>
          <div className="ml-8 mt-3 mb-5">
            <Link
              className={`${
                linkText === "View More" ? "bg-green-600" : "bg-red-600"
              }  rounded px-3 py-2 w-max text-xs  text-white`}
              onClick={updateCredentials}
            >
              {linkText}
            </Link>
          </div> */}
        </div>
      </div>
      </>
    );
  else if (currentComp === "testDetails")
    return (
      <>
        <div className="w-full">
          <div className="flex sm:ml-5">
            <div className="flex flex-col items-center sm:w-8/12 lg:w-6/12 ">
              <div className="flex justify-between items-center w-full px-2">
                <Link
                  onClick={() => setCurrentComp("testList")}
                  className="flex items-center text-gray-700 w-full justify-start p-5"
                >
                  <img
                    className="w-7 h-7 outline-none"
                    src="https://nuvocliniq-test.web.app/back.svg"
                  ></img>
                  <div className="mx-4">Test details</div>
                </Link>
              </div>
              <div className="px-3 pb-5 my-5 w-full border-b-2  border-gray-200"></div>
              <div>
                <div
                  className="flex justify-start mx-5"
                  style={{ fontSize: "15px" }}
                 >
                  <div className=" text-gray-500 w-max rounded">
                    {`${currentItem.createAt.toDate()}`.slice(0, 25)}
                  </div>
                </div>
                <div
                  name="profile fields"
                  className="sm:flex my-5 justify-around w-full px-5"
                 >
                  <div className="flex flex-col space-y-3">
                    <div className="w-min">
                      <div>Name:</div>
                      <div className="mt-2 w-max">{currentItem.name}</div>
                    </div>
                    <div className=" ">
                      <div>Number:</div>
                      <div className="mt-2 bg-green-100 text-green-600 text-base max-w-min rounded py-0.5 px-2">
                        {currentItem.number}
                      </div>
                    </div>
                    <div className=" ">
                      <div>Email:</div>
                      <div className="mt-2 bg-blue-100 text-blue-600 rounded px-2 w-min text-base">
                        {currentItem.email}
                      </div>
                    </div>
                    <div className=" ">
                      <div>Address:</div>
                      <div className="mt-2 text-sm">{currentItem.address}</div>
                    </div>
                  </div>
                  <div className="flex flex-col space-y-3 ml-0 sm:ml-5">
                    <div className=" ">
                      <div>Age:</div>
                      <div className="mt-2 max-w-min bg-yellow-100 text-yellow-500 rounded py-0.5 px-2 flex justify-center">
                        {currentItem.age}
                      </div>
                    </div>
                    <div className="">
                      <div>Gender:</div>
                      <div
                        className={`mt-2 w-max bg-green-100 text-green-600 text-base rounded py-0.5 px-2 flex justify-center`}
                      >
                        {currentItem.gender}
                      </div>
                    </div>
                    <div className="">
                      <div className="">Tests:</div>
                      <div
                        className={`mt-2 w-full bg-green-100 text-green-600 text-base rounded py-2 px-2`}
                      >
                        {currentItem.tests.map((item, index) => {
                          return (
                            <div className="flex">
                              <div>{index + 1}.</div>
                              <div className="ml-2">{item.name}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};


import React from "react";

export const UnderConst = (props) => {
  return (
    <div>
      <div className=" mx-2 sm:mx-10 flex justify-start items-center text-gray-700 text-lg sm:text-xl">
        <div className="bg-blue-100 text-blue-500 w-7 h-7 flex justify-center items-center rounded-lg">
          <i style={{ fontSize: "16px" }} class="fas fa-chart-line"></i>
        </div>
        <div className="ml-4 text-lg">{props.heading}</div>
      </div>
      <div className="w-full h-96 mt-20 flex justify-center items-center">
        <div className="flex flex-col items-center">
          <img className="w-56" src="https://nuvocliniq-test.web.app/empty.svg"></img>
          <div className="flex justify-center text-base w-max border-2 py-1 px-2 rounded-lg items-center mt-5 text-gray-800">
            Under construction
          </div>
        </div>
      </div>
    </div>
  );
};

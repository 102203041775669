import React from "react";
import { Link } from "react-router-dom";


export const LeftSideMenu = (props) => {
  
  return (
    <div
      name="leftMenu"
      className="w-auto sm:px-8 px-1 min-h-screen bg-gradient-to-br to-blue-600 from-blue-800 flex flex-col items-center text-white"
    >
      <div className="mt-6 flex flex-col items-center">
        <img src='https://firebasestorage.googleapis.com/v0/b/nuvoclinic-ad7c7.appspot.com/o/logo.svg?alt=media&token=9094262f-aa48-4d4f-91fd-db7e1704a83d' className="h-16"></img>
        <div className='font-semibold text-xl mt-2'>Nuvocliniq</div>
      </div>

      <div className=" mt-5">
      <Link  to="/">
        <div className={`${props.active==='dashboard'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
          <i class="fas fa-tachometer-alt"></i>
          <div className="ml-4">
            Dashboard
          </div>
        </div>
        </Link>
        <Link to="/appointments" >
        <div className={`${props.active==='appointments'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
        <i class="far fa-calendar-check"></i>
          <div className="ml-4">
            Appointments
          </div>
        </div>
        </Link>
        <Link to="/patients" >
        <div className={`${props.active==='patients'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
        <i class="fas fa-hospital-user"></i>
          <div className="ml-4">
            Patients
          </div>
        </div>
        </Link>
        <Link to="/doctors" >
        <div className={`${props.active==='doctors'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
        <i class="fas fa-user-md"></i>
          <div className="ml-4">
            Doctors
          </div>
        </div>
        </Link>
        <Link to="/transactions" >
        <div className={`${props.active==='transactions'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
        <i class="fas fa-rupee-sign"></i>
          <div className="ml-4">
            Transactions
          </div>
        </div>
        </Link>
        <Link  to='/rt-pcr' >
        <div className={`${props.active==='covid'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
          <i class="fas fa-virus"></i>
          <div className="ml-4">
          Covid-19
          </div>
        </div>
        </Link>
        <Link  to='/tests' >
        <div className={`${props.active==='tests'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
        <i class="fas fa-vial"></i>
          <div className="ml-4">
          Tests
          </div>
        </div>
        </Link>
        <Link  to='/visits' >
        <div className={`${props.active==='visits'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
        <i class="fas fa-briefcase-medical"></i>
          <div className="ml-4">
            Visits
          </div>
        </div>
        </Link>
        <Link  to='/data' >
        <div className={`${props.active==='data'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
        <i class="fas fa-database"></i>
          <div className="ml-4">
            Data
          </div>
        </div>
        </Link>
        <Link  >
        <div className={`${props.active==='discounts'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
          <i class="fas fa-percentage"></i>
          <div className="ml-4">
            Discounts
          </div>
        </div>
        </Link>
        <Link  >
        <div className={`${props.active==='upcomings'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
          <i class="fas fa-asterisk"></i>
          <div className="ml-4">
            Upcomings
          </div>
        </div>
        </Link>
        <Link >
        <div className={`${props.active==='cities'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
          <i class="fas fa-window-restore"></i>
          <div className="ml-4">
            Cities
          </div>
        </div>
        </Link>
        <Link  >
        <div className={`${props.active==='messages'?'bg-green-600 py-2':''} px-4  rounded-full mt-4 mb-3 text-md flex justify-start items-center`}>
          <i class="far fa-comments"></i>
          <div className="ml-4">
            Messages
          </div>
        </div>
        </Link>
      </div>
    </div>
  );
};

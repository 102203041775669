import React, { useState } from "react";
import { Link } from "react-router-dom";

import { db } from "../firebase";
//import { ToastContainer, toast } from "react-toastify";

import { useAuth } from "../contexts";
import { LeftSideMenu } from "../components/leftSideMenu";
import { TopBar } from "../components/topBar";


//console.log('xlsxlog',window.XLSX);
var XLSX = window.XLSX
export const ExcelToJson = (props) => {
  const [jsonData, setJsonData] = useState(null);
  let data = [
    {
      name: "jayanth",
      data: "scd",
      abc: "sdef",
    },
  ];

  const convertToJson = (e) => {
    let selectedFile = e.target.files[0];
    XLSX.utils.json_to_sheet(data, "out.xlsx");
    if (selectedFile) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event) => {
        let data = event.target.result;
        let workbook = XLSX.read(data, { type: "binary" });
        //console.log(workbook);
        workbook.SheetNames.forEach((sheet) => {
          let rowObject = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[sheet]
          );
          //console.log(rowObject);
          setJsonData(rowObject);
        });
      };
    }
  };

  return (
    <div //className={'w-28 h-36 flex justify-center items-center'}
    >
      <input
        id="my_file_input"
        type="file"
        accept=".xls,.xlsx"
        onChange={convertToJson}
      ></input>
      <pre>{JSON.stringify(jsonData, undefined, 4)}</pre>
    </div>
  );
};

import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import app from "../firebase";
import firebase from "firebase/app";
import { auth, db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../contexts";
import { LeftSideMenu } from "../components/leftSideMenu";
import { TopBar } from "../components/topBar";
import { UnderConst } from "../components/underConst";

export const Dashboard = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());
  var history = useHistory();

  if (!loggedIn.currentUser) {
    history.push("/signin");
    return <div></div>;
  } else if (loggedIn.currentUser)
    return (
      <div className=" min-w-full min-h-screen flex overflow-x-hidden bg-white">
        <LeftSideMenu active='dashboard'/>
        <div name="otherThanLeftMenu" className="w-full h-full">
          <TopBar/>
          <UnderConst heading='Dashboard' />
        </div>
      </div>
    );
};

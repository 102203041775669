import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
// import app from "../firebase";
import { auth } from "../firebase";
import { ToastContainer, toast } from "react-toastify";

import { useAuth } from "../contexts";

export const TopBar = (props) => {
  const handleLogout = (e) => {
    auth
      .signOut()
      .then((res) => (window.location.href = "/signin"))
      .catch((err) => console.log("false"));
  };

  return (
    <div
      name="topBar"
      className="w-full text-black my-3 pr-5 flex justify-end items-center"
    >
      <Link style={{ fontSize: "15px" }} className='text-red-600 bg-red-100 px-3 py-1 rounded-full'
       onClick={handleLogout}>
        {/* <i style={{ fontSize: "35px" }} className="fas fa-user-circle"></i> */}
        Logout
      </Link>
    </div>
  );
};

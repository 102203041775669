import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { db } from "../../firebase";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

//import { ToastContainer, toast } from "react-toastify";

import { useAuth } from "../../contexts";
import { LeftSideMenu } from "../../components/leftSideMenu";
import { TopBar } from "../../components/topBar";

export const Transaction = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());

  if (!loggedIn.currentUser) window.location.href = "/signin";

  let [transactions, setTransactions] = useState([]);
  let [sortBy, setSortBy] = useState("name");
  useEffect(() => {
    //console.log(campDrop.value.id, campDrop.value.id.length);
    db.collection("transaction")
      .orderBy(`${sortBy}`)
      .get()
      .then((res) => {
        let tempArray = [];
        res.forEach((doc) => {
          tempArray.push(doc.data());
        });
        //console.log(tempArray);
        setTransactions(tempArray);
      });
  }, [sortBy]);
  console.log(transactions);

  return (
    <div className=" min-w-full max-h-screen flex overflow-hidden overscroll-none bg-white">
      <LeftSideMenu active="transactions" />
      <div name="otherThanLeftMenu" className="w-full h-full">
        <TopBar />
        <Link
          onClick={(e) => {
            e.preventDefault();
            window.history.go(-1);
          }}
          className="flex items-center text-gray-700 w-full justify-start sm:ml-3 px-2 py-5 sm:p-5"
        >
          <img
            className="w-7 h-7 outline-none"
            src="https://nuvocliniq-test.web.app/back.svg"
          ></img>
          <div className="mx-4">List of Transactions</div>
        </Link>
        <div className=" flex justify-end space-x-7 m-5">
          <div>Sort By:</div>
          <Link
            style={{ fontSize: "15px" }}
            className="text-blue-600 bg-blue-100 px-3 py-1 rounded-full"
            onClick={() => setSortBy("name")}
          >
            {/* <i style={{ fontSize: "35px" }} className="fas fa-user-circle"></i> */}
            Name
          </Link>
          <Link
            style={{ fontSize: "15px" }}
            className="text-blue-600 bg-blue-100 px-3 py-1 rounded-full"
            onClick={() => setSortBy("createAt")}
          >
            {/* <i style={{ fontSize: "35px" }} className="fas fa-user-circle"></i> */}
            Date
          </Link>
        </div>
        <div
          name="transactionsList"
          className="text-black ml-5 flex justify-start"
        >
          <div style={{ height: "500px" }} className="w-full overflow-y-auto">
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      Name
                    </div>
                  </Th>
                  <Th>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      Number
                    </div>
                  </Th>
                  <Th>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      Date
                    </div>
                  </Th>
                  <Th>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      Charge
                    </div>
                  </Th>
                  <Th>
                    <div style={{ display: "flex", justifyContent: "start" }}>
                      Mode
                    </div>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {transactions.map((item, index) => {
                  if (item.name)
                    return (
                      <Tr style={{ margin: "10px 0px 10px 0px" }} key={index}>
                        <Td>{item.name}</Td>
                        <Td>{item.number}</Td>
                        <Td>{`${item.createAt.toDate()}`.slice(0, 25)}</Td>
                        <Td>{item.amount}</Td>
                        <Td>{item.type}</Td>
                      </Tr>
                    );
                })}
              </Tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

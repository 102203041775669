import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {  db } from "../firebase";
//import { ToastContainer, toast } from "react-toastify";

import { useAuth } from "../contexts";
import { LeftSideMenu } from "../components/leftSideMenu";
import { TopBar } from "../components/topBar";

export const Doctors = (props) => {
  let [doctorsArray, setDoctorsArray] = useState();
  let [last, setLast] = useState();
  let [linkText, setLinkText] = useState("View More");
  let [loggedIn, setLoggedIn] = useState(useAuth())

  if (!loggedIn.currentUser) window.location.href='/signin'

  useEffect(() => {
    const initialCredentials = async () => {
      if (!doctorsArray) {
        const first = db.collection("doctors").orderBy("name").limit(4);
        const docSnap = await first.onSnapshot((snapshot) => {
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          setDoctorsArray(snapshot.docs);
          setLast(lastItem);
        });
      }
    };
    return initialCredentials();
  }, []);

  const updateCredentials = async (e) => {
    e.preventDefault()
    if (doctorsArray) {
      const next = db
        .collection("doctors")
        .orderBy("name")
        .startAfter(last.data().name)
        .limit(4);
      const snapshot = await next.get();
      if (snapshot.docs.length !== 0) {
        const lastItem = snapshot.docs[snapshot.docs.length - 1];
        setDoctorsArray([...doctorsArray, ...snapshot.docs]);
        setLast(lastItem);
        //console.log("next", doctorsArray, last.data());
      } else setLinkText("No More Doctors");
    }
  };
  const DoctorCard = (props) => {
    let item = props.item
    return(
      <Link
        onClick={(e) => {
          e.preventDefault()
          window.location.href = `/doctorView/${item.id}`
      }}
          className="rounded-md border-2 border-gray-100 shadow-md m-1 sm:m-3 pb-2"
        >
          <div className="w-52 sm:w-72 p-3">
            <div className="sm:flex  w-max sm:mr-5 ml-20 sm:ml-0">
              <img
                className=" w-10 h-10 rounded-full mr-3"
                src={item.data().img ? item.data().img : "https://nuvocliniq-test.web.app/man.png"}
              ></img>

              <div className="flex flex-col justify-center w-60 ">
                <div className="font-semibold w-max max-w-full text-base">
                  {item.data().name}
                </div>
                <div className="text-sm">{item.data().email}</div>
              
              </div>
            </div>
          </div>
          <div className="flex px-3 my-1">
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className=" sm:mr-5 mr-2 text-blue-700 px-2 bg-blue-100 text-sm rounded w-max"
            >
              {item.data().city}
            </div>
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className={`${item.data().verify ? "text-green-600 bg-green-100" : "text-red-600 bg-red-100"} w-max rounded  px-2 text-sm`}
            >
              {item.data().verify ? "Verified" : "Unverified"}
            </div>
          </div>
          {/* <div className="flex px-3 my-2">
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className="w-max text-yellow-700 bg-yellow-100 text-sm rounded  px-2 flex justify-center"
            >
              {item.data().status}
            </div>
            <div
              style={{ paddingBottom: "2px", paddingTop: "2px" }}
              className="mx-6  w-max text-indigo-700 bg-indigo-100 text-sm rounded  px-2 flex justify-center"
            >
              {item.data().type}
            </div>
          </div> */}
        </Link>
    )
  }
  const DoctorsList = (props) => {
    if (!doctorsArray) return <div></div>;
    else if (doctorsArray)
      return (
        <div className=" lg:flex flex-wrap justify-start overflow-hidden overscroll-none">
          {doctorsArray.map((item) => {
            return (
              <DoctorCard item={item} />
            );
          })}
        </div>
      );
  };
    return (
      <div className=" min-w-full min-h-screen flex overflow-hidden overscroll-none bg-white">
        <LeftSideMenu active="doctors" />
        <div name="otherThanLeftMenu" className="w-full h-full">
          <TopBar />
          <Link
            onClick={(e) => {
              e.preventDefault();
              window.history.go(-1);
            }}
            className="flex items-center text-gray-700 w-full justify-start sm:ml-3 px-2 py-5 sm:p-5"
          >
            <img
              className="w-7 h-7 outline-none"
              src="https://nuvocliniq-test.web.app/back.svg"
            ></img>
            <div className="mx-4">List of doctors</div>
          </Link>
          <div
            name="doctorsList"
            className="text-black ml-5 flex justify-start"
          >
            <DoctorsList />
          </div>
          <div className="ml-8 mt-3 mb-5">
            <Link
              className={`${linkText==="View More"?'bg-green-600':'bg-red-600'}  rounded px-3 py-2 w-max text-xs  text-white`}
              onClick={updateCredentials}
            >
              {linkText}
            </Link>
          </div>
        </div>
      </div>
    );
};

import React, { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { auth } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../contexts";

export const SignIn = (props) => {
  let [loggedIn, setLoggedIn] = useState(useAuth());

  var emailRef = useRef();
  var passRef = useRef();
  var history = useHistory();

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    var email = emailRef.current.value;
    var pass = passRef.current.value;

    if (!email || !pass)
      sendToast("Fields marked with * are important!", "info");
    else if (!reEmail.test(String(email).toLowerCase()))
      sendToast("Enter valid Email", "error");
    else if (email !== "cso@nouveausurge.com")
      sendToast("Wrong Credentials", "error");
    // Sign
    else {
      auth
        .signInWithEmailAndPassword(email, pass)
        .then((res) => {
          sendToast("Successfully signed in!", "success");
        })
        .then(() => (window.location.href = "/"))
        .catch((error) => {
          console.log(error.code, error.message);
          if (error.code === "auth/user-not-found")
            sendToast("Email not registered ", "error");
          else if (error.code === "auth/wrong-password")
            sendToast("Invalid Credentials", "error");
          else if (error.code === "auth/too-many-requests")
            sendToast(
              "Account has been temporarily disabled due to many failed login attempts ",
              "error"
            );
        });
    }
  };
  if (loggedIn.currentUser) {
    history.push("/");
    return <div></div>;
  } else if (!loggedIn.currentUser)
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="w-screen h-screen flex justify-center overflow-x-hidden pt-5 pb-20">
          <div className="sm:w-1/2 w-full h-auto bg-white sm:p-10 p-5">
            <div className="w-full flex flex-col items-center space-y-5">
              <img
                className="w-32 h-32"
                src="https://firebasestorage.googleapis.com/v0/b/nuvoclinic-ad7c7.appspot.com/o/logo.svg?alt=media&token=9094262f-aa48-4d4f-91fd-db7e1704a83d"
              ></img>
              <div className="sm:text-3xl text-xl font-semibold">
                Signin to Nuvocliniq Admin
              </div>
            </div>

            <div className="mt-10 w-full">
              <div>
                <p className="flex flex-col  items-start">Email address</p>
                <input placeholder='Enter email address'
                  ref={emailRef}
                  className="my-4 p-3 w-full bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
              <div>
                <p className="flex flex-col  items-start">Password</p>
                <input placeholder='Enter password'
                  ref={passRef}
                  className="my-4 p-3 w-full  bg-gray-100 border-2 border-gray-200 rounded-md outline-none"
                ></input>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                onClick={handleSubmit}
                className="bg-green-600 w-max rounded-md py-3 px-14  my-3 text-white"
              >
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

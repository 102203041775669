import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { db } from "../firebase";

import { convertArrayToCSV } from "convert-array-to-csv";

import { useAuth } from "../contexts";
import { LeftSideMenu } from "../components/leftSideMenu";
import { TopBar } from "../components/topBar";

export const CovidTests = (props) => {
  let [testArray, settestArray] = useState();
  let [last, setLast] = useState();
  let [linkText, setLinkText] = useState("View More");
  let [currentComp, setCurrentComp] = useState("testList");
  let [currentItem, setCurrentItem] = useState();
  let [excelLink, setExcelLink] = useState();
  let [loggedIn, setLoggedIn] = useState(useAuth());

  useEffect(() => {
    const initialCredentials = async () => {
      if (!testArray) {
        const first = db.collection("covidRTPCR").orderBy("name").limit(10);
        const docSnap = await first.onSnapshot((snapshot) => {
          const lastItem = snapshot.docs[snapshot.docs.length - 1];
          //console.log(snapshot.docs[0].data());
          settestArray(snapshot.docs);
          setLast(lastItem);
        });
      }
    };
    return initialCredentials();
  }, []);

  const updateCredentials = async () => {
    if (testArray) {
      const next = db
        .collection("covidRTPCR")
        .orderBy("name")
        .startAfter(last.data().name)
        .limit(10);
      const snapshot = await next.get();
      if (snapshot.docs.length !== 0) {
        const lastItem = snapshot.docs[snapshot.docs.length - 1];
        settestArray([...testArray, ...snapshot.docs]);
        setLast(lastItem);
        //console.log("next", doctorsArray, last.data());
      } else setLinkText("No More Tests");
    }
  };

  const createExcel = async (e) => {
    e.preventDefault();
    const header = [
      "Sr no",
      "Area",
      "Name",
      "Gender",
      "Age",
      "Mobile number",
      "Aadhar",
      "Address",
      "Date of Request",
      "E-mail",
    ];
    let dataArray = [];

    for (var i = 0; i < testArray.length; i++) {
      dataArray.push([
        i + 1,
        "area",
        testArray[i].data().name,
        testArray[i].data().gender,
        testArray[i].data().age,
        testArray[i].data().number,
        testArray[i].data().aadhar,
        testArray[i].data().address,
        // testArray[i].data().city,
        testArray[i].data().date,
        testArray[i].data().email,
      ]);
    }

    const csvFromArrayOfArrays = convertArrayToCSV(dataArray, {
      header,
      separator: ";",
    });
    //console.log(csvFromArrayOfArrays);

    var csvFile = await new Blob([csvFromArrayOfArrays], {
      //type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      type: "application/vnd.ms-excel"
    });
    setExcelLink(URL.createObjectURL(csvFile))
    document.getElementById("openExcel").click();
    //console.log(URL.createObjectURL(csvFile));
  };

  const TestCard = (props) => {
    let item = props.item;
    return (
      <Link
        onClick={(e) => {
          e.preventDefault();
          setCurrentItem(item.data())
          setCurrentComp('testDetails')
        }}
        className="rounded-md border-2 border-gray-100 shadow-md m-1 sm:m-3 pb-2"
      >
        <div className="w-52 sm:w-72 p-3">
          <div className="sm:flex  w-max sm:mr-5 ml-20 sm:ml-0">
            <img
              className=" w-10 h-10 rounded-full mr-3"
              src={
                item.data().img
                  ? item.data().img
                  : "https://nuvocliniq-test.web.app/man.png"
              }
            ></img>

            <div className="flex flex-col justify-center w-60 ">
              <div className="font-semibold w-max max-w-full text-base">
                {item.data().name}
              </div>
              <div className="text-sm">{item.data().number}</div>
            </div>
          </div>
        </div>
        <div className="flex px-3 my-1 space-x-5">
          <div
            style={{ paddingBottom: "2px", paddingTop: "2px" }}
            className={` text-green-600 bg-green-100  w-max rounded  px-2 text-sm`}
          >
            {item.data().gender}
          </div>
          <div
            style={{ paddingBottom: "2px", paddingTop: "2px" }}
            className={` text-yellow-600 bg-yellow-100  w-max rounded  px-2 text-sm`}
          >
            {item.data().age}
          </div>
        </div>
      </Link>
    );
  };
  const TestList = (props) => {
    if (!testArray) return <div></div>;
    else if (testArray)
      return (
        <div className=" lg:flex flex-wrap justify-start overflow-hidden overscroll-none">
          {testArray.map((item) => {
            return <TestCard item={item} />;
          })}
        </div>
      );
  };
  if (currentComp === "testList")
    return (
      <div className=" min-w-full min-h-screen flex overflow-hidden overscroll-none  bg-white">
        <LeftSideMenu active="covid" />
        <div name="otherThanLeftMenu" className="w-full h-full">
          <TopBar />
          <div className="sm:flex justify-between items-center w-full pr-5">
            <Link
              onClick={(e) => {
                e.preventDefault();
                window.history.go(-1);
              }}
              className="flex items-center text-gray-700  justify-start sm:ml-3 px-2 py-5 sm:p-5"
            >
              <img
                className="w-7 h-7 outline-none"
                src="https://nuvocliniq-test.web.app/back.svg"
              ></img>
              <div className="mx-4">List of tests</div>
            </Link>
            <div>
              <button
                className="bg-green-600 hover:bg-green-700 text-white h-10 text-base px-4 py-1 rounded-md"
                onClick={createExcel}
              >
                Generate report ({testArray?testArray.length:''})
              </button>
              <a download='report.xls' href={excelLink} target="_blank" id="openExcel"></a>
            </div>
          </div>
          <div
            name="TestList"
            className="text-black ml-5 flex justify-start"
          >
            <TestList />
          </div>
          <div className="ml-8 mt-3 mb-5">
            <Link
              className={`${
                linkText === "View More" ? "bg-green-600" : "bg-red-600"
              }  rounded px-3 py-2 w-max text-xs  text-white`}
              onClick={updateCredentials}
            >
              {linkText}
            </Link>
          </div>
        </div>
      </div>
    );
  else if (currentComp === "testDetails")
    return (
      <>
        <div className="w-full">
          <div className="flex sm:ml-10">
            <div className="flex flex-col items-center sm:w-8/12 lg:w-6/12 ">
              <div className="flex justify-between items-center w-full px-2">
                <Link
                  onClick={() => setCurrentComp("testList")}
                  className="flex items-center text-gray-700 w-full justify-start p-5"
                >
                  <img
                    className="w-7 h-7 outline-none"
                    src="https://nuvocliniq-test.web.app/back.svg"
                  ></img>
                  <div className="mx-4">Test details</div>
                </Link>
              </div>
              <div name="profilePic" className=" flex mt-14 sm:w-80">
                <div className=" shadow-md rounded-full">
                  <img
                    className="w-20 h-20 rounded-full"
                    src={
                      currentItem.img
                        ? currentItem.img
                        : "https://nuvocliniq-test.web.app/man.png"
                    }
                  ></img>
                </div>
                <div className="ml-4 mt-2">
                  <div className="font-semibold">{currentItem.name}</div>
                  <div className="text-base">{currentItem.number}</div>
                </div>
              </div>
              <div className="px-3 pb-5 my-5 w-full lg:w-10/12 border-b-2  border-gray-200 flex justify-end"></div>
              <div
                name="profile fields"
                className="sm:flex my-5 justify-around w-full px-5"
              >
                <div className="flex flex-col ">
                  <div className="my-3 w-min">
                    <div>Age:</div>
                    <div className="my-2 px-2 rounded text-base bg-yellow-100 text-yellow-600">
                      {currentItem.age}
                    </div>
                  </div>
                  <div className="my-3 ">
                    <div>Gender:</div>
                    <div className="my-2 bg-green-100 text-green-600 text-base max-w-min rounded py-0.5 px-2">
                      {currentItem.gender}
                    </div>
                  </div>
                  <div className="my-3 ">
                    <div>Date:</div>
                    <div className="my-2 bg-blue-100 text-blue-600 rounded px-2 w-min text-base">{currentItem.date}</div>
                  </div>
                  <div className="my-3 ">
                    <div>Address:</div>
                    <div className="my-2 text-sm">
                      {currentItem.address}
                    </div>
                  </div>
                  
                </div>
                <div className="flex flex-col">
                  <div className="my-3 ">
                    <div>Aadhar number:</div>
                    <div className="my-2 max-w-min bg-yellow-100 text-yellow-500 rounded py-0.5 px-2 flex justify-center">
                      {currentItem.aadhar}
                    </div>
                  </div>
                  <div className="my-2.5">
                    <div className="my-2">City:</div>
                    <div
                      className={`my-2 w-max bg-green-100 text-green-600 text-base rounded py-0.5 px-2 flex justify-center`}
                    >
                      {currentItem.city}
                    </div>
                  </div>
                  <div className="my-3">
                    <div>Email:</div>
                    <div className="bg-blue-100 text-blue-600 w-max rounded my-2 px-2 py-0.5">
                      {currentItem.email}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

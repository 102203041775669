import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";

import { db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import { LeftSideMenu } from "../components/leftSideMenu";
import { TopBar } from "../components/topBar";
import { useAuth } from "../contexts";

export const DoctorView = (props) => {
  let [initialData, setInitialData] = useState();
  let [clinicsArray, setClinicsArray] = useState([]);
  let [totalClinics, setTotalClinics] = useState([])
  let [selectClinic, setSelectClinic] = useState('')
  let [loggedIn, setLoggedIn] = useState(useAuth())

  if (!loggedIn.currentUser) window.location.href='/signin'

  var params = useParams();

  var feeRef = useRef();
  var profileRef = useRef();
  var specialistRef = useRef();
  var addressRef = useRef();
  var statusRef = useRef();
  var clinicRef = useRef()
  var payableFeeRef = useRef()

  useEffect(() => {
    db.collection("doctors")
      .doc(`${params.docId}`)
      .onSnapshot((snap) => {
        console.log(snap.id);
        setInitialData(snap.data());
      });
  }, []);

  useEffect(async () => {
    if (initialData && initialData.clinics) {
      let tempArray = [];
      for (var i = 0; i < initialData.clinics.length; i++) {
        await db
          .collection("clinics")
          .doc(initialData.clinics[i])
          .get()
          .then((res) => {
            tempArray.push(res.data());
          });
      }
      //console.log(tempArray)
      setClinicsArray(tempArray);
    }
  }, [initialData]);

  const sendToast = (message, type) => {
    const tostMsg = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    if (type === "error") toast.error(message, tostMsg);
    else if (type === "info") toast.info(message, tostMsg);
    else if (type === "success") toast.success(message, tostMsg);
  };


  const handleUpdate = (e) => {
    e.preventDefault();
    let address = addressRef.current.value;
    let profile = profileRef.current.value;
    let fee = feeRef.current.value;
    let specialist = specialistRef.current.value;
    let status = statusRef.current.value;
    let payableFee = payableFeeRef.current.value

    db.collection("doctors")
      .doc(`${params.docId}`)
      .update({
        address,
        profile,
        fee,payableFee,
        specialist,
        verify: status === "verified" ? true : false,
      })
      .then((res) => sendToast('Successfully update the profile', 'success'))
      .catch((err) => console.log(err));
  };
  if (initialData)
    return (
      <>
      <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      <div className=" min-w-full min-h-screen flex overflow-x-hidden bg-white">
        <LeftSideMenu active="doctors" />
        <div name="otherThanLeftMenu" className="w-full h-full">
          <TopBar />
          <div className="text-black flex flex-col ml-10">
            <div className="flex my-10">
              <div className="mr-5 w-28 h-28">
                <img
                  className=" w-28 h-28 rounded-full"
                  src={initialData.img}
                ></img>
              </div>
              <div className="flex flex-col justify-around pl-10">
                <div className="font-semibold">{initialData.name}</div>
                <div>{initialData.email}</div>
                <div>{initialData.city}</div>
              </div>
            </div>
          </div>
          <div className="ml-10 pb-5">
            <div className="my-5">
              <div>Number</div>
              <div className="font-semibold">{initialData.number}</div>
            </div>
            <div className="my-5">
              <div>Address</div>
              <input
                ref={addressRef}
                defaultValue={initialData.address}
                className="font-semibold border-2 rounded-md px-2 py-1 outline-none"
              ></input>
            </div>
            <div className="my-5">
              <div>Profile</div>
              <input
                ref={profileRef}
                defaultValue={initialData.profile}
                className="font-semibold border-2 rounded-md px-2 py-1 outline-none"
              ></input>
            </div>
            <div className="my-5">
              <div>Specialist</div>
              <select
                ref={specialistRef}
                defaultValue={initialData.specialist}
                className="font-semibold border-2 rounded-md px-2 py-1 outline-none"
              >
                <option>General physician</option>
                <option>Health advisor</option>
                <option>Cardiologist</option>
                <option>Audiologist</option>
                <option>Dentist</option>
                <option>Dentist</option>
                <option>ENT Specialist</option>
                <option>Gynaecologist</option>
                <option>Orthopaedic surgeon</option>
                <option>Paediatrician</option>
                <option>Psychiatrists</option>
                <option>Veterinarian</option>
                <option>Radiologist</option>
                <option>Pulmonologist</option>
                <option>Endocrinologist</option>
                <option>Oncologist</option>
                <option>Neurologist</option>
                <option>Cardiothoracic surgeon</option>
                <option>Oncologist</option>
              </select>
            </div>
            <div className="my-5">
              <div>Clinics</div>
              <div className={`${initialData.clinics?'hidden':''}`}>No Clinics added</div>
              <div>
                {clinicsArray.map((item) => {
                  return <div>- {item.name}</div>;
                })}
              </div>
              <div className="text-white my-3 flex flex-col">
                <select ref={clinicRef} onChange={(e) => {
                    e.preventDefault()
                    if (clinicRef.current.value==='select clinic') return null
                    let requiredId
                    let idsArray=[]
                    clinicsArray.map(item => {
                        idsArray.push(item.id)
                    })
                    if (selectClinic==='add') {
                        totalClinics.map(item => {
                            if (item.name === clinicRef.current.value && idsArray.indexOf(item.id)<0) requiredId=item.id
                            else console.log('already')
                        })
                        
                        //console.log([...idsArray, requiredId])
                        if (requiredId)
                        db.collection('doctors').doc(`${params.docId}`).update({clinics: [...idsArray, requiredId]})
                        .then(res=> console.log('updated'))
                    }
                    else if (selectClinic==='remove') {
                        let toPush=[]
                        clinicsArray.map(item => {
                            if (item.name === clinicRef.current.value) requiredId=item.id
                        })
                        idsArray.map(item=> {
                            if(item !== requiredId) toPush.push(item)
                        })
                        //console.log(toPush)
                        db.collection('doctors').doc(`${params.docId}`).update({clinics: toPush})
                    }
                }}
                 className={`${selectClinic===''?'hidden':''} border-2 rounded-md px-2 py-1 outline-none mb-3 w-max text-black`}>
                     <option>select clinic</option>
                    {
                        selectClinic==='add'?totalClinics.map(item=>{
                            return( <option>{item.name}</option>)
                        }):selectClinic==='remove'?clinicsArray.map(item=>{
                            return(<option>{item.name}</option>)
                        }):''
                    }
                </select>
                <div>
                    <Link onClick={(e)=> {
                        e.preventDefault()
                        db.collection('clinics').where('city', '==', initialData.city).get()
                        .then(res => {
                            let tempArray=[]
                            res.docs.map(item=> {
                                tempArray.push(item.data())
                            })
                            setTotalClinics(tempArray)
                        })
                        setSelectClinic('add')
                    }}
                    className="ml-1 bg-blue-600 outline-none rounded-md py-1 px-2">
                    Add Clinic
                    </Link>
                    <Link onClick={(e)=> {
                        e.preventDefault()
                        setSelectClinic('remove')
                    }}
                    className={`${initialData.clinics?'':'hidden'} outline-none ml-3 bg-red-600 rounded-md  py-1 px-2`}>
                    Remove Clinic
                    </Link>
                </div>
              </div>
            </div>
            <div className="my-5">
              <div>Fee (in ₹)</div>
              <input
                ref={feeRef}
                defaultValue={initialData.fee}
                className="font-semibold border-2 rounded-md px-2 py-1 outline-none"
              ></input>
            </div>
            <div className="my-5">
              <div>Payable Fee (in ₹)</div>
              <input
                ref={payableFeeRef}
                defaultValue={initialData.payableFee}
                className="font-semibold border-2 rounded-md px-2 py-1 outline-none"
              ></input>
            </div>
            <div className="my-5">
              <div>Status</div>
              <select className='border-2 rounded-md px-2 py-1 outline-none'
                ref={statusRef}
                defaultValue={`${
                  initialData.verify ? "verified" : "unverified"
                }`}
              >
                <option>verified</option>
                <option>unverified</option>
              </select>
            </div>
            <div className="flex justify-end w-1/2">
              <Link
                onClick={handleUpdate}
                className="rounded-sm px-2 py-1 w-max mt-2 text-white"
                style={{
                  backgroundColor: "green",
                }}
              >
                Update
              </Link>
            </div>
          </div>
        </div>
      </div>
      </>
    );
  else return <div></div>;
};
